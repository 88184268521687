import { template as template_71a53b24d22f4078ab60817f1112b26e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_71a53b24d22f4078ab60817f1112b26e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
