import { template as template_5d6cf65b89de4b7ba969ff4682e1079f } from "@ember/template-compiler";
const FKLabel = template_5d6cf65b89de4b7ba969ff4682e1079f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
