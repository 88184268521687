import { template as template_4cdfd1f4fd5a4ff5822157f0ddfb617f } from "@ember/template-compiler";
const FKControlMenuContainer = template_4cdfd1f4fd5a4ff5822157f0ddfb617f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
