import { template as template_58cec814481f41a989e0b1533bd82bf5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_58cec814481f41a989e0b1533bd82bf5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
