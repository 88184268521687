import { template as template_c523942a245740e0b1db5cd45e2b82a6 } from "@ember/template-compiler";
const WelcomeHeader = template_c523942a245740e0b1db5cd45e2b82a6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
